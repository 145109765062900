import { View, StyleSheet, Text, Pressable } from "@/ui";
import { Colors } from "@/constants";
import Icon from "./Icon";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useUISlice } from "@/models/useUISlice";
import useFileSelector from "./fileSelector";
import PrimaryButton from "./PrimaryButton";

const LandingPageActionButton = ({
  loading,
  onPress,
}: {
  loading: boolean;
  onPress: (callback: () => void) => void;
}) => {
  const isMobile = useUISlice((state) => state.isMobile);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const pickerRef = useRef(null);
  const { openFilePicker } = useFileSelector();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <View style={{ alignSelf: "center", zIndex: 10 }} ref={pickerRef}>
      <View style={s.container}>
        <PrimaryButton
          style={{
            ...s.button,
            backgroundColor: isMobile ? "transparent" : Colors.accent,
          }}
          textStyle={{
            ...s.buttonText,
            ...(isMobile && {
              textAlign: "center",
              lineHeight: 34,
              fontSize: 24,
              color: Colors.accent,
            }),
          }}
          text={t("landingPageActionButton.actionText")}
          onPress={() => setVisible(!visible)}
          loading={loading}
        />
      </View>

      {visible && (
        <View style={s.pickerContainer}>
          <Pressable
            onPress={() => {
              setVisible(false);
              onPress(() =>
                useUISlice.getState().setAlertWidgetVisible("recorder")
              );
            }}
            style={{ ...s.pickerItem }}
          >
            <Icon icon="mic" style={s.pickerItemIcon} />

            <Text
              numberOfLines={1}
              style={{ fontSize: 18, color: Colors.white }}
            >
              {t("landingPageActionButton.recordAudio")}
            </Text>
          </Pressable>

          <View style={{ height: 1, backgroundColor: Colors.gray8 }} />

          <Pressable
            onPress={() => {
              setVisible(false);
              onPress(() => openFilePicker());
            }}
            style={{ ...s.pickerItem }}
          >
            <Icon icon="upload" style={s.pickerItemIcon} />

            <Text
              numberOfLines={1}
              style={{ fontSize: 18, color: Colors.white }}
            >
              {t("landingPageActionButton.uploadFile")}
            </Text>
          </Pressable>
        </View>
      )}
    </View>
  );
};

const s = StyleSheet.create({
  container: { marginBottom: 16, marginTop: 24 },
  button: {
    backgroundColor: Colors.accent,
    paddingHorizontal: 48,
    height: 64,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    color: Colors.white,
    fontSize: 26,
  },
  pickerContainer: {
    position: "absolute",
    bottom: 90,
    left: 0,
    width: "100%",
    alignSelf: "center",
    backgroundColor: Colors.text,
    paddingHorizontal: 12,
    borderRadius: 8,
    minWidth: 280,
  },
  pickerItem: {
    paddingVertical: 20,
    paddingHorizontal: 16,
    borderBottomColor: Colors.gray8,
    flexDirection: "row",
    alignItems: "center",
  },
  pickerItemIcon: {
    tintColor: Colors.white,
    height: 24,
    width: 24,
    marginRight: 12,
  },
});

export default LandingPageActionButton;
