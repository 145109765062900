import { useEffect } from "react";
import { useUISlice } from "@/models/useUISlice";
import { useUserSlice } from "@/models/useUserSlice";
import { datadogLogs } from "@datadog/browser-logs";
import { t } from "i18next";
import { useFilePicker } from "use-file-picker";
import { SUPPORTED_FILE_EXTENSIONS } from "@/constants";

const useFileSelector = () => {
  const { openFilePicker, plainFiles } = useFilePicker({
    multiple: false,
    accept: SUPPORTED_FILE_EXTENSIONS,
  });

  useEffect(() => {
    const selectFile = async () => {
      if (!plainFiles || !plainFiles[0]) return;

      const extension = `.${plainFiles[0].name.split(".").pop()}`.toLowerCase();
      const isAudioOrVideo =
        plainFiles[0].type.toLowerCase().includes("audio") ||
        plainFiles[0].type.toLowerCase().includes("video");

      if (!SUPPORTED_FILE_EXTENSIONS.includes(extension) && !isAudioOrVideo) {
        datadogLogs.logger.info("Unsupported file", {
          deviceId: useUserSlice.getState().deviceId,
          name: plainFiles[0].name,
        });

        return useUISlice.getState().setAlertModal({
          title: t("landingPage.unSupportedFile"),
          subtitle: t("landingPage.unSupportedFileMessage", {
            fileName: plainFiles[0].name,
          }),
          button: {
            text: t("landingPage.ok"),
            onPress: () => useUISlice.getState().setAlertModal(undefined),
          },
        });
      }

      datadogLogs.logger.info("Funnel 2: Import file", {
        deviceId: useUserSlice.getState().deviceId,
        language: useUserSlice.getState().transcriptionOptions?.languageCode,
        fileSize: plainFiles[0].size,
      });

      useUISlice.getState().setAlertStartTranscription(plainFiles[0]);
    };

    selectFile();
  }, [plainFiles]);

  return {
    openFilePicker,
    selectedFile: plainFiles?.[0],
  };
};

export default useFileSelector;
