import { datadogLogs } from "@datadog/browser-logs";
import { View, Text, StyleSheet, Pressable, Image } from "@/ui/index";
import Header from "@/ui/Header";
import { Colors } from "@/constants";
import { icons } from "@/ui/Icon";
import LandingPageActionButton from "@/ui/LandingPageActionButton";
import { getMobileOperatingSystem } from "@/models/utils";
import { useUISlice } from "@/models/useUISlice";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Paywall from "@/ui/Paywall";
import { datadogRum } from "@datadog/browser-rum";
import { useAuth } from "@/models/auth";
import LandingFooter from "@/ui/LandingFooter";
import { AuthUser } from "@/api-lib";

const LandingPage = () => {
  const isMobile = useUISlice((state) => state.isMobile);
  const [loading, setLoading] = useState(false);
  const { currentAuthUser } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    const authUser = currentAuthUser();

    if (authUser) {
      datadogRum.setUser({ id: authUser.deviceId, email: authUser.email });
    }
  }, []);

  const _contactUs = (type: "footer_section" | "need_more_section") => {
    const subject = {
      footer_section: "I have a question about 1Transcribe web app",
      need_more_section: "I have custom transcription demands",
    };

    _openLink(
      `mailto:hello@1transcribe.com?subject=${encodeURI(subject[type])}`
    );
  };

  const _openLink = (link: string) => {
    if (typeof window !== "object") return;
    window.open(link, "_blank");
  };

  const _onActionButtonPress = async (callback: () => void) => {
    const deviceOS = getMobileOperatingSystem();

    if (deviceOS === "iOS") {
      return callback();
    }

    setLoading(true);

    const userAccess = await Paywall.getUserAccess();

    setLoading(false);

    if (userAccess.canTranscribe) {
      return callback();
    }

    useUISlice.getState().setAlertModal({
      title: t("landingPage.limitReached"),
      subtitle: t("landingPage.limitReachedMessage"),
      button: {
        text: t("landingPage.limitReachedButton"),
        onPress: () => {
          useUISlice.getState().setAlertModal(undefined);
        },
      },
    });
  };

  return (
    <View style={s.container}>
      <View
        style={{ flex: 1, maxWidth: 1000, width: "100%", alignSelf: "center" }}
      >
        <Header />

        <View style={{ ...s.mainContent, ...(isMobile && { marginTop: 24 }) }}>
          <Text
            accessibilityRole="header"
            aria-level={1}
            style={{
              ...s.title,
              ...(isMobile && { fontSize: 32 }),
            }}
          >
            {t("landingPage.headerTitle")}
          </Text>
          <Text
            accessibilityRole="header"
            aria-level={2}
            style={{ ...s.subtitle, ...(isMobile && { fontSize: 16 }) }}
          >
            {t("landingPage.headerSubtitle")}
          </Text>

          <View style={s.actionButtonContainer}>
            <LandingPageActionButton
              loading={loading}
              onPress={_onActionButtonPress}
            />
          </View>

          <View
            style={{
              ...s.featuresContainer,
              ...(isMobile && { flexDirection: "column" }),
            }}
          >
            <View
              style={{
                ...s.featureItem,
                ...(isMobile && { marginVertical: 25 }),
              }}
            >
              <Image
                source={{ uri: icons.emojiTarget }}
                style={s.featureIcon}
              />
              <Text style={s.featureTitle}>{t("landingPage.feature1")}</Text>
              <Text style={s.featureText}>{t("landingPage.feature1Text")}</Text>
            </View>

            <View
              style={{
                ...s.featureItem,
                ...(isMobile && { marginVertical: 25 }),
              }}
            >
              <Image
                source={{ uri: icons.emojiSpeaker }}
                style={s.featureIcon}
              />
              <Text style={s.featureTitle}>{t("landingPage.feature2")}</Text>
              <Text style={s.featureText}>{t("landingPage.feature2Text")}</Text>
            </View>

            <View
              style={{
                ...s.featureItem,
                ...(isMobile && { marginVertical: 25 }),
              }}
            >
              <Image source={{ uri: icons.emojiLock }} style={s.featureIcon} />
              <Text style={s.featureTitle}>{t("landingPage.feature3")}</Text>
              <Text style={s.featureText}>{t("landingPage.feature3Text")}</Text>
            </View>
          </View>

          <View
            style={{
              ...s.pricingContainer,
              ...(isMobile && { flexDirection: "column" }),
            }}
          >
            <View
              style={{
                ...s.pricingCard,
                ...(isMobile && { marginVertical: 16, paddingBottom: 32 }),
              }}
            >
              <Text style={s.pricingTitle}>{t("landingPage.priceCard1")}</Text>
              <Text style={s.pricingDesc}>
                {t("landingPage.priceCard1Subtitle")}
              </Text>
              <Text style={s.pricingFeature}>
                <Text style={{ color: "green" }}>✓</Text>{" "}
                {t("landingPage.priceCard1Feature1")}
              </Text>
              <Text style={s.pricingPrice}>
                {t("landingPage.priceCard1Price")}
              </Text>
            </View>

            <View
              style={{
                ...s.pricingCard,
                ...(isMobile && { marginVertical: 16, paddingBottom: 32 }),
              }}
            >
              <Text style={s.pricingTitle}>{t("landingPage.priceCard2")}</Text>
              <Text style={s.pricingDesc}>
                {t("landingPage.priceCard2Subtitle")}
              </Text>
              <Text style={s.pricingFeature}>
                <Text style={{ color: "green" }}>✓</Text>{" "}
                {t("landingPage.priceCard2Feature1")}
              </Text>
              <Text style={s.pricingPrice}>
                {t("landingPage.priceCard2Price")}
              </Text>
            </View>

            <View
              style={{
                ...s.pricingCard,
                ...(isMobile && { marginVertical: 16, paddingBottom: 32 }),
              }}
            >
              <Text style={s.pricingTitle}>{t("landingPage.priceCard3")}</Text>
              <Text style={s.pricingDesc}>
                {t("landingPage.priceCard3Subtitle")}
              </Text>
            </View>
          </View>

          <View style={s.needMoreSection}>
            <View>
              <Text style={s.needMoreTitle}>{t("landingPage.needMore")}</Text>
              <Text style={s.needMoreText}>
                {t("landingPage.needMoreText")}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Pressable
                onPress={() => _contactUs("need_more_section")}
                style={s.contactButton}
              >
                <Text style={s.contactButtonText}>
                  {t("landingPage.contactUs")}
                </Text>
              </Pressable>
            </View>
          </View>
        </View>

        <LandingFooter />
      </View>
    </View>
  );
};

LandingPage.downloadButton = (authUser: AuthUser) => {
  const deviceOS = getMobileOperatingSystem();

  if (deviceOS === "iOS") {
    datadogLogs.logger.info(
      `clicked on download button for iOS app `,
      authUser
    );

    return window.open(
      "https://apps.apple.com/us/app/1transcribe-dictation-to-text/id6705123880",
      "_blank"
    );
  }

  datadogLogs.logger.info(
    `clicked on download button for Android app `,
    authUser
  );

  return window.open(
    "https://play.google.com/store/apps/details?id=com.transcribe.audio.to.text",
    "_blank"
  );
};

const s = StyleSheet.create({
  container: { flex: 1 },
  mainContent: {
    marginTop: 50,
    padding: 20,
  },
  title: {
    fontSize: 40,
    fontWeight: "600",
    color: Colors.title,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 20,
    color: Colors.subtitle,
    lineHeight: 30,
  },
  actionButtonContainer: {
    marginTop: 20,
    height: 320,
    backgroundColor: Colors.gray3,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
  },
  convertButton: {
    backgroundColor: Colors.accent,
    paddingHorizontal: 48,
    height: 64,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  convertButtonText: {
    color: Colors.white,
    fontSize: 28,
  },
  featuresContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 50,
  },
  featureItem: {
    flex: 1,
    alignItems: "center",
    padding: 10,
  },
  featureIcon: {
    width: 50,
    height: 50,
    marginBottom: 10,
  },
  featureTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  featureText: {
    textAlign: "center",
    fontSize: 16,
    lineHeight: 24,
    color: Colors.text,
  },
  pricingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 50,
  },
  pricingCard: {
    flex: 1,
    padding: 20,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.borderColor,
    margin: 4,
  },
  pricingTitle: {
    fontSize: 20,
    color: Colors.title,
    fontWeight: "bold",
    marginBottom: 24,
  },
  pricingDesc: {
    color: Colors.text,
    fontSize: 18,
    lineHeight: 28,
    marginBottom: 32,
  },
  pricingFeature: {
    marginBottom: 32,
    fontSize: 18,
    color: Colors.text,
  },
  pricingPrice: {
    fontSize: 24,
    color: Colors.text,
    fontWeight: "bold",
    marginBottom: 12,
  },
  needMoreSection: {
    backgroundColor: Colors.hover,
    padding: 32,
    borderRadius: 8,
    marginTop: 50,
  },
  needMoreTitle: {
    fontSize: 24,
    color: Colors.title,
    fontWeight: "bold",
    marginBottom: 10,
  },
  needMoreText: {
    fontSize: 18,
    color: Colors.text,
    lineHeight: 28,
    marginBottom: 20,
  },
  contactButton: {
    backgroundColor: Colors.accent,
    padding: 16,
    borderRadius: 8,
  },
  contactButtonText: {
    fontSize: 16,
    fontWeight: "500",
    color: Colors.white,
  },
});

export default LandingPage;
